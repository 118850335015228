<template>
  <section id="hero">
    <v-img
      :min-height="minHeight"
      :max-height="maxHeight"
      :src="require('@/assets/bghd.jpg')"
      class="white--text "
    >
      <v-container class="fill-height px-4 py-12">
        <v-responsive
          class="d-flex align-center"
          height="100%"
          width="100%"
        >
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="6"
            >
              <base-img
                :src="require(`@/assets/product.png`)"
                width="100%"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="6"
              class="d-flex align-center"
            >
              <div class="d-block">
                <base-heading title="Hadir dengan Kemasan Baru" />
                <p class="subtitle">
                  Kandungan dan Manfaatnya 100% Sama!
                </p>
                <div
                  :class="$vuetify.breakpoint.smAndDown ? 'flex-column align-start' : 'align-center'"
                  class="d-flex flex-wrap"
                >
                  <router-link to="/scan">
                    <base-btn
                      class="v-btn v-btn-primary mr-2 mb-2"
                      router-to="/scan"
                    >
                      Cek Orisinal Produk
                    </base-btn>
                  </router-link>

                  <router-link
                    v-if="isSignIn"
                    to="/scan"
                  >
                    <base-btn class="mr-2 mb-2">
                      Claim Reward
                    </base-btn>
                  </router-link>
                  <v-dialog
                    v-else
                    width="500"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <base-btn
                        class="mr-2 mb-2"
                        v-bind="attrs"
                        v-on="on"
                      >
                        Claim Reward
                      </base-btn>
                    </template>

                    <v-card>
                      <v-card-text class="mt-5 p-2 text-center">
                        <h4 class="pt-2 pb-2">
                          Untuk Melanjutkan silahkan Masuk dulu menggunakan Google
                        </h4>
                        <base-btn
                          :disabled="!isInit"
                          style="width:100%"
                          @click="handleClickSignIn"
                        >
                          Sign In With Google
                        </base-btn>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                  <!-- <base-btn class="mr-2 mb-2"
                    @click="handleClickSignOut"
                    v-if="isSignIn">
                    Sign Out
                  </base-btn> -->
                </div>
              </div>
            </v-col>
          </v-row>
        </v-responsive>
      </v-container>
    </v-img>
  </section>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'SectionHero',

    provide: {
      theme: { isDark: true },
    },

    data () {
      return {
        isInit: false,
        isSignIn: false,
      }
    },
    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
      maxHeight () {
        return `calc(100vh - 30px)`
      },
    },
    created () {
      const that = this
      const checkGauthLoad = setInterval(function () {
        that.isInit = that.$gAuth.isInit
        that.isSignIn = that.$gAuth.isAuthorized
        if (that.isInit) clearInterval(checkGauthLoad)
      }, 1000)
    },
    methods: {
      async handleClickUpdateScope () {
        const option = new window.gapi.auth2.SigninOptionsBuilder()
        option.setScope('email https://www.googleapis.com/auth/drive.file')
        const googleUser = this.$gAuth.GoogleAuth.currentUser.get()
        try {
          await googleUser.grant(option)
          console.log('success')
        } catch (error) {
          console.error(error)
        }
      },
      handleClickLogin () {
        this.$gAuth
          .getAuthCode()
          .then((authCode) => {
            // on success
            console.log('authCode', authCode)
          })
          .catch((error) => {
            console.error(error)
          })
      },
      async handleClickSignIn () {
        try {
          const googleUser = await this.$gAuth.signIn()
          if (!googleUser) {
            return null
          }

          this.authToBackend(googleUser.getAuthResponse().access_token)
          this.isSignIn = this.$gAuth.isAuthorized
        } catch (error) {
          // on fail do something
          console.error(error)
          return null
        }
      },
      async handleClickSignOut () {
        try {
          await this.$gAuth.signOut()
          this.isSignIn = this.$gAuth.isAuthorized
          console.log('isSignIn', this.$gAuth.isAuthorized)
          localStorage.clear()
        } catch (error) {
          console.error(error)
        }
      },
      handleClickDisconnect () {
        window.location.href = `https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue=${window.location.href}`
      },
      authToBackend (accessToken) {
        // axios.post('http://localhost:8000/api/google/callback', {
        axios.post('https://backoffice.fitsea.co.id/api/google/callback', {
          accessToken: accessToken,
        })
          .then(response => {
            if (response.data.valid == true) {
              this.saveToLocal(response.data)
            }
          })
      },
      saveToLocal (data) {
        localStorage.userId = data.userId
        localStorage.token = data.token
      },
    },
  }
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');
  .v-application .display-2 {
    font-family: Montserrat !important;
  }
  .v-application .display-1 {
    letter-spacing: 0 !important;
  }
  h1, h2, h3, h4, h5, p {
    font-family: Montserrat !important;
    font-weight: 700;
  }
  p.subtitle {
    font-size: 14pt;
  }
</style>
